import { useIntl } from "react-intl";
import SuccessSection from "@ticketingplatform/ui/dist/components/sections/Checkout/SuccessMessage";
import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useEventHeading from "hooks/useEventHeading";

const SuccessPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const eventHeading = useEventHeading();

  return (
    <>
      <Header />
      <SuccessSection
        successMessage={formatMessage({
          defaultMessage:
            "Thank you. You should receive an email confirmation with your QR code.",
          description: "Success message",
        })}
        {...{ eventHeading }}
      />
    </>
  );
};

export default SuccessPage;
