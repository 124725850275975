/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EventStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum EventType {
  NONTICKETED = "NONTICKETED",
  TICKETED = "TICKETED",
}

export enum OrderStatus {
  CHECKOUT = "CHECKOUT",
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  EXPIRED = "EXPIRED",
}

export enum PaymentType {
  CASH = "CASH",
  POS = "POS",
  STRIPE_CHECKOUT = "STRIPE_CHECKOUT",
  STRIPE_TERMINAL = "STRIPE_TERMINAL",
}

export enum Permission {
  BOTTLE_SERVICE_LIST = "BOTTLE_SERVICE_LIST",
  BOTTLE_SERVICE_READ = "BOTTLE_SERVICE_READ",
  DISCOUNT_CODE_CREATE = "DISCOUNT_CODE_CREATE",
  DISCOUNT_CODE_DELETE = "DISCOUNT_CODE_DELETE",
  DISCOUNT_CODE_LIST = "DISCOUNT_CODE_LIST",
  DISCOUNT_CODE_READ = "DISCOUNT_CODE_READ",
  DISCOUNT_CODE_UPDATE = "DISCOUNT_CODE_UPDATE",
  EVENT_CREATE = "EVENT_CREATE",
  EVENT_DELETE = "EVENT_DELETE",
  EVENT_LIST = "EVENT_LIST",
  EVENT_READ = "EVENT_READ",
  EVENT_UPDATE = "EVENT_UPDATE",
  IMAGE_CREATE = "IMAGE_CREATE",
  ORDER_CHECKOUT_CASH = "ORDER_CHECKOUT_CASH",
  ORDER_CHECKOUT_POS = "ORDER_CHECKOUT_POS",
  ORDER_CHECKOUT_STRIPE_TERMINAL = "ORDER_CHECKOUT_STRIPE_TERMINAL",
  ORDER_CREATE = "ORDER_CREATE",
  ORDER_LIST = "ORDER_LIST",
  ORDER_READ = "ORDER_READ",
  ORDER_UPDATE = "ORDER_UPDATE",
  ORGANIZATION_CREATE = "ORGANIZATION_CREATE",
  ORGANIZATION_DELETE = "ORGANIZATION_DELETE",
  ORGANIZATION_LIST = "ORGANIZATION_LIST",
  ORGANIZATION_READ = "ORGANIZATION_READ",
  ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE",
  PAYMENT_TERMINAL_CREATE = "PAYMENT_TERMINAL_CREATE",
  PAYMENT_TERMINAL_DELETE = "PAYMENT_TERMINAL_DELETE",
  PAYMENT_TERMINAL_LIST = "PAYMENT_TERMINAL_LIST",
  PAYMENT_TERMINAL_READ = "PAYMENT_TERMINAL_READ",
  REFUND_CREATE = "REFUND_CREATE",
  REFUND_LIST = "REFUND_LIST",
  SEAT_MAP_CREATE = "SEAT_MAP_CREATE",
  SEAT_MAP_LIST = "SEAT_MAP_LIST",
  SETTINGS_EMBED_READ = "SETTINGS_EMBED_READ",
  SIGN_UP_OPTIONS_LIST = "SIGN_UP_OPTIONS_LIST",
  TICKET_LIST = "TICKET_LIST",
  TICKET_POOL_READ = "TICKET_POOL_READ",
  TICKET_READ = "TICKET_READ",
  TICKET_TEMPLATE_CREATE = "TICKET_TEMPLATE_CREATE",
  TICKET_TEMPLATE_DELETE = "TICKET_TEMPLATE_DELETE",
  TICKET_TEMPLATE_LIST = "TICKET_TEMPLATE_LIST",
  TICKET_TEMPLATE_READ = "TICKET_TEMPLATE_READ",
  TICKET_TEMPLATE_UPDATE = "TICKET_TEMPLATE_UPDATE",
  TICKET_UPDATE = "TICKET_UPDATE",
  USER_CREATE = "USER_CREATE",
  USER_DELETE = "USER_DELETE",
  USER_LIST = "USER_LIST",
  USER_READ = "USER_READ",
  USER_UPDATE = "USER_UPDATE",
  VENUE_CREATE = "VENUE_CREATE",
  VENUE_DELETE = "VENUE_DELETE",
  VENUE_LIST = "VENUE_LIST",
  VENUE_READ = "VENUE_READ",
  VENUE_UPDATE = "VENUE_UPDATE",
  VENUE_USER_CREATE = "VENUE_USER_CREATE",
  VENUE_USER_DELETE = "VENUE_USER_DELETE",
  VENUE_USER_LIST = "VENUE_USER_LIST",
  VENUE_USER_READ = "VENUE_USER_READ",
  VENUE_USER_UPDATE = "VENUE_USER_UPDATE",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum RecurringSettingsMonthRepeatMode {
  LAST_WEEK_DAY = "LAST_WEEK_DAY",
  MONTH_DAY = "MONTH_DAY",
  WEEK_DAY = "WEEK_DAY",
}

export enum RecurringSettingsSeparator {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export enum RefundMethod {
  STRIPE = "STRIPE",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export enum TicketStatus {
  DELETED = "DELETED",
  NEW = "NEW",
  SCANNED = "SCANNED",
}

export enum UserRole {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
}

export enum VenueUserRole {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  STAFF = "STAFF",
}

export interface AddressFilterInput {
  address1?: StringFilterInput | null;
  address2?: StringFilterInput | null;
  city?: StringFilterInput | null;
  country?: StringFilterInput | null;
  countryCode?: StringFilterInput | null;
  province?: StringFilterInput | null;
  provinceCode?: StringFilterInput | null;
  zip?: StringFilterInput | null;
}

export interface AddressOrderByInput {
  address1?: SortDirection | null;
  address2?: SortDirection | null;
  city?: SortDirection | null;
  country?: SortDirection | null;
  countryCode?: SortDirection | null;
  createdAt?: SortDirection | null;
  province?: SortDirection | null;
  provinceCode?: SortDirection | null;
  updatedAt?: SortDirection | null;
  zip?: SortDirection | null;
}

export interface AddressUpdateInput {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  countryCode?: string | null;
  province?: string | null;
  provinceCode?: string | null;
  zip?: string | null;
}

export interface BooleanFilterInput {
  equals?: boolean | null;
}

export interface DateTimeFilterInput {
  equals?: AWSDateTime | null;
  gt?: AWSDateTime | null;
  gte?: AWSDateTime | null;
  in?: AWSDateTime[] | null;
  lt?: AWSDateTime | null;
  lte?: AWSDateTime | null;
  notIn?: AWSDateTime[] | null;
}

export interface DiscountCodeCreateInput {
  amount?: number | null;
  code?: string | null;
  eventIds?: (number | null)[] | null;
  expireAt?: AWSDateTime | null;
  isApplicableToAllEvents?: boolean | null;
  maxUse?: number | null;
  name?: string | null;
  numberOfTicketsAppliedInOrder?: number | null;
  venueId?: number | null;
}

export interface DiscountCodeFilterInput {
  AND?: DiscountCodeFilterInput[] | null;
  NOT?: DiscountCodeFilterInput[] | null;
  OR?: DiscountCodeFilterInput[] | null;
  amount?: IntFilterInput | null;
  code?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  eventDiscountCodes?: EventDiscountCodeListRelationFilterInput | null;
  expireAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  isApplicableToAllEvents?: BooleanFilterInput | null;
  maxUse?: IntFilterInput | null;
  name?: StringFilterInput | null;
  numberOfTicketsAppliedInOrder?: IntFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venueId?: IntFilterInput | null;
}

export interface DiscountCodeOrderByInput {
  amount?: SortDirection | null;
  code?: SortDirection | null;
  createdAt?: SortDirection | null;
  expireAt?: SortDirection | null;
  id?: SortDirection | null;
  isApplicableToAllEvents?: SortDirection | null;
  maxUse?: SortDirection | null;
  name?: SortDirection | null;
  numberOfTicketsAppliedInOrder?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueId?: SortDirection | null;
}

export interface DiscountCodeUpdateInput {
  amount?: number | null;
  code?: string | null;
  eventIds?: (number | null)[] | null;
  expireAt?: AWSDateTime | null;
  isApplicableToAllEvents?: boolean | null;
  maxUse?: number | null;
  name?: string | null;
  numberOfTicketsAppliedInOrder?: number | null;
}

export interface EventCreateInput {
  ageLimit?: number | null;
  date?: AWSDateTime | null;
  description?: string | null;
  discountCodeIds?: number[] | null;
  doorTime?: number | null;
  imageId?: number | null;
  name?: string | null;
  publishAt?: AWSDateTime | null;
  recurringSettings?: RecurringSettingsCreateInput | null;
  seatMapId?: number | null;
  status?: EventStatus | null;
  ticketPools?: TicketPoolCreateInput[] | null;
  timezone?: string | null;
  type?: EventType | null;
  venueId?: number | null;
  youtubeUrl?: string | null;
}

export interface EventDiscountCodeFilterInput {
  AND?: EventDiscountCodeFilterInput[] | null;
  NOT?: EventDiscountCodeFilterInput[] | null;
  OR?: EventDiscountCodeFilterInput[] | null;
  discountCodeId?: IntFilterInput | null;
  eventId?: IntFilterInput | null;
}

export interface EventDiscountCodeListRelationFilterInput {
  every?: EventDiscountCodeFilterInput | null;
  none?: EventDiscountCodeFilterInput | null;
  some?: EventDiscountCodeFilterInput | null;
}

export interface EventFilterInput {
  AND?: EventFilterInput[] | null;
  NOT?: EventFilterInput[] | null;
  OR?: EventFilterInput[] | null;
  ageLimit?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  date?: DateTimeFilterInput | null;
  description?: StringFilterInput | null;
  discountCodes?: EventDiscountCodeListRelationFilterInput | null;
  doorTime?: IntFilterInput | null;
  image?: ImageFilterInput | null;
  imageId?: IntFilterInput | null;
  name?: StringFilterInput | null;
  publishAt?: DateTimeFilterInput | null;
  recurringSettings?: RecurringSettingsFilterInput | null;
  recurringSettingsId?: IntFilterInput | null;
  seatMap?: SeatMapFilterInput | null;
  seatMapId?: IntFilterInput | null;
  status?: EventStatusEnumFilter | null;
  ticketPools?: TicketPoolListRelationFilterInput | null;
  type?: EventTypeEnumFilter | null;
  updatedAt?: DateTimeFilterInput | null;
  venue?: VenueFilterInput | null;
  venueId?: IntFilterInput | null;
  youtubeUrl?: StringFilterInput | null;
}

export interface EventListRelationFilterInput {
  every?: EventFilterInput | null;
  none?: EventFilterInput | null;
  some?: EventFilterInput | null;
}

export interface EventOccurrenceFilterInput {
  AND?: EventOccurrenceFilterInput[] | null;
  NOT?: EventOccurrenceFilterInput[] | null;
  OR?: EventOccurrenceFilterInput[] | null;
  ageLimit?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  description?: StringFilterInput | null;
  discountCodes?: EventDiscountCodeListRelationFilterInput | null;
  doorTime?: IntFilterInput | null;
  image?: ImageFilterInput | null;
  imageId?: IntFilterInput | null;
  name?: StringFilterInput | null;
  publishAt?: DateTimeFilterInput | null;
  recurringSettings?: RecurringSettingsFilterInput | null;
  recurringSettingsId?: IntFilterInput | null;
  seatMap?: SeatMapFilterInput | null;
  seatMapId?: IntFilterInput | null;
  status?: EventStatusEnumFilter | null;
  ticketPools?: TicketPoolListRelationFilterInput | null;
  type?: EventTypeEnumFilter | null;
  updatedAt?: DateTimeFilterInput | null;
  venue?: VenueFilterInput | null;
  venueId?: IntFilterInput | null;
  youtubeUrl?: StringFilterInput | null;
}

export interface EventOrderByInput {
  ageLimit?: SortDirection | null;
  createdAt?: SortDirection | null;
  date?: SortDirection | null;
  description?: SortDirection | null;
  doorTime?: SortDirection | null;
  name?: SortDirection | null;
  publishAt?: SortDirection | null;
  recurringSettings?: RecurringSettingsOrderByInput | null;
  seatMap?: SeatMapOrderByInput | null;
  status?: SortDirection | null;
  type?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venue?: VenueOrderByInput | null;
}

export interface EventStatusEnumFilter {
  equals?: EventStatus | null;
  in?: EventStatus[] | null;
  not?: EventStatusEnumFilter | null;
  notIn?: EventStatus[] | null;
}

export interface EventTypeEnumFilter {
  equals?: EventType | null;
  in?: EventType[] | null;
  not?: EventTypeEnumFilter | null;
  notIn?: EventType[] | null;
}

export interface EventUpdateInput {
  ageLimit?: number | null;
  date?: AWSDateTime | null;
  description?: string | null;
  discountCodeIds?: (number | null)[] | null;
  doorTime?: number | null;
  imageId?: number | null;
  name?: string | null;
  publishAt?: AWSDateTime | null;
  recurringSettings?: RecurringSettingsCreateInput | null;
  seatMapId?: number | null;
  status?: EventStatus | null;
  ticketPools?: (TicketPoolCreateInput | null)[] | null;
  timezone?: string | null;
  type?: EventType | null;
  youtubeUrl?: string | null;
}

export interface FloatFilterInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  notIn?: number[] | null;
}

export interface ImageCreateInput {
  name: string;
  size: number;
  type: string;
}

export interface ImageFilterInput {
  AND?: ImageFilterInput[] | null;
  NOT?: ImageFilterInput[] | null;
  OR?: ImageFilterInput[] | null;
  bucket?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  key?: StringFilterInput | null;
  name?: StringFilterInput | null;
  size?: IntFilterInput | null;
  type?: StringFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
}

export interface IntFilterInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  notIn?: number[] | null;
}

export interface OrderCreateInput {
  autoCheckIn?: boolean | null;
  customerNotes?: string | null;
  discountCode?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  newsletterAgree?: boolean | null;
  paymentTerminalId?: number | null;
  paymentType?: PaymentType | null;
  phoneNumber?: string | null;
  sendReceipt?: boolean | null;
  status?: OrderStatus | null;
  termsAgree?: boolean | null;
  tickets?: TicketCreateInput[] | null;
}

export interface OrderFilterInput {
  AND?: OrderFilterInput[] | null;
  NOT?: OrderFilterInput[] | null;
  OR?: OrderFilterInput[] | null;
  checkoutUrl?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  customerNotes?: StringFilterInput | null;
  discountAmount?: FloatFilterInput | null;
  discountCodeId?: IntFilterInput | null;
  email?: StringFilterInput | null;
  expireAt?: DateTimeFilterInput | null;
  firstName?: StringFilterInput | null;
  id?: IntFilterInput | null;
  lastName?: StringFilterInput | null;
  newsletterAgree?: BooleanFilterInput | null;
  orderNumber?: StringFilterInput | null;
  paymentFeeAmount?: FloatFilterInput | null;
  phoneNumber?: StringFilterInput | null;
  status?: OrderStatusEnumFilter | null;
  subtotalAmount?: FloatFilterInput | null;
  termsAgree?: BooleanFilterInput | null;
  tickets?: TicketListRelationFilterInput | null;
  totalAmount?: FloatFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venueFeeAmount?: FloatFilterInput | null;
}

export interface OrderOrderByInput {
  checkoutUrl?: SortDirection | null;
  createdAt?: SortDirection | null;
  customerNotes?: SortDirection | null;
  discountAmount?: SortDirection | null;
  discountCodeId?: SortDirection | null;
  email?: SortDirection | null;
  expireAt?: SortDirection | null;
  firstName?: SortDirection | null;
  id?: SortDirection | null;
  lastName?: SortDirection | null;
  newsletterAgree?: SortDirection | null;
  orderNumber?: SortDirection | null;
  paymentFeeAmount?: SortDirection | null;
  phoneNumber?: SortDirection | null;
  status?: SortDirection | null;
  subtotalAmount?: SortDirection | null;
  termsAgree?: SortDirection | null;
  totalAmount?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueFeeAmount?: SortDirection | null;
}

export interface OrderStatusEnumFilter {
  equals?: OrderStatus | null;
  in?: OrderStatus[] | null;
  not?: OrderStatusEnumFilter | null;
  notIn?: OrderStatus[] | null;
}

export interface OrganizationCreateInput {
  name?: string | null;
}

export interface OrganizationOrderByInput {
  createdAt?: SortDirection | null;
  name?: SortDirection | null;
  updatedAt?: SortDirection | null;
}

export interface OrganizationUpdateInput {
  name?: string | null;
}

export interface OrganizationsFilterInput {
  name?: StringFilterInput | null;
}

export interface PaymentTerminalCreateInput {
  name: string;
  stripeTerminalRegistrationCode: string;
  venueId?: number | null;
}

export interface PaymentTerminalFilterInput {
  AND?: PaymentTerminalFilterInput[] | null;
  NOT?: PaymentTerminalFilterInput[] | null;
  OR?: PaymentTerminalFilterInput[] | null;
  createdAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  name?: StringFilterInput | null;
  serialNumber?: StringFilterInput | null;
  stripeTerminalId?: StringFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venue?: VenueFilterInput | null;
  venueId?: IntFilterInput | null;
}

export interface PaymentTerminalOrderByInput {
  createdAt?: SortDirection | null;
  id?: SortDirection | null;
  name?: SortDirection | null;
  serialNumber?: SortDirection | null;
  stripeTerminalId?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueId?: SortDirection | null;
}

export interface RecurringSettingsCreateInput {
  endAt?: AWSDateTime | null;
  isOnFriday?: boolean | null;
  isOnMonday?: boolean | null;
  isOnSaturday?: boolean | null;
  isOnSunday?: boolean | null;
  isOnThursday?: boolean | null;
  isOnTuesday?: boolean | null;
  isOnWednesday?: boolean | null;
  maxOccurrences?: number | null;
  monthRepeatMode?: RecurringSettingsMonthRepeatMode | null;
  separator: RecurringSettingsSeparator;
  separatorCount: number;
}

export interface RecurringSettingsFilterInput {
  AND?: RecurringSettingsFilterInput[] | null;
  NOT?: RecurringSettingsFilterInput[] | null;
  OR?: RecurringSettingsFilterInput[] | null;
  createdAt?: DateTimeFilterInput | null;
  endAt?: DateTimeFilterInput | null;
  events?: EventListRelationFilterInput | null;
  isOnFriday?: BooleanFilterInput | null;
  isOnMonday?: BooleanFilterInput | null;
  isOnSaturday?: BooleanFilterInput | null;
  isOnSunday?: BooleanFilterInput | null;
  isOnThursday?: BooleanFilterInput | null;
  isOnTuesday?: BooleanFilterInput | null;
  isOnWednesday?: BooleanFilterInput | null;
  maxOccurrences?: IntFilterInput | null;
  separator?: RecurringSettingsSeparatorEnumFilter | null;
  separatorCount?: IntFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
}

export interface RecurringSettingsOrderByInput {
  createdAt?: SortDirection | null;
  endAt?: SortDirection | null;
  isOnFriday?: SortDirection | null;
  isOnMonday?: SortDirection | null;
  isOnSaturday?: SortDirection | null;
  isOnSunday?: SortDirection | null;
  isOnThursday?: SortDirection | null;
  isOnTuesday?: SortDirection | null;
  isOnWednesday?: SortDirection | null;
  maxOccurrences?: SortDirection | null;
  separator?: SortDirection | null;
  separatorCount?: SortDirection | null;
  updatedAt?: SortDirection | null;
}

export interface RecurringSettingsSeparatorEnumFilter {
  equals?: RecurringSettingsSeparator | null;
  in?: RecurringSettingsSeparator[] | null;
  not?: RecurringSettingsSeparatorEnumFilter | null;
  notIn?: RecurringSettingsSeparator[] | null;
}

export interface RefundCreateInput {
  amount?: number | null;
  method?: RefundMethod | null;
  orderId?: number | null;
  reason?: string | null;
}

export interface RefundFilterInput {
  AND?: RefundFilterInput[] | null;
  NOT?: RefundFilterInput[] | null;
  OR?: RefundFilterInput[] | null;
  amount?: FloatFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  method?: RefundMethodEnumFilter | null;
  orderId?: IntFilterInput | null;
  reason?: StringFilterInput | null;
  stripeAccount?: StringFilterInput | null;
  stripeId?: StringFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
}

export interface RefundMethodEnumFilter {
  equals?: RefundMethod | null;
  in?: RefundMethod[] | null;
  not?: RefundMethodEnumFilter | null;
  notIn?: RefundMethod[] | null;
}

export interface RefundOrderByInput {
  amount?: SortDirection | null;
  createdAt?: SortDirection | null;
  id?: SortDirection | null;
  method?: SortDirection | null;
  orderId?: SortDirection | null;
  reason?: SortDirection | null;
  stripeAccount?: SortDirection | null;
  stripeId?: SortDirection | null;
  updatedAt?: SortDirection | null;
}

export interface SeatMapCreateInput {
  imageId?: number | null;
  name: string;
  venueId?: number | null;
}

export interface SeatMapFilterInput {
  AND?: SeatMapFilterInput[] | null;
  NOT?: SeatMapFilterInput[] | null;
  OR?: SeatMapFilterInput[] | null;
  createdAt?: DateTimeFilterInput | null;
  events?: EventListRelationFilterInput | null;
  image?: ImageFilterInput | null;
  imageId?: IntFilterInput | null;
  name?: StringFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venue?: VenueFilterInput | null;
  venueId?: IntFilterInput | null;
}

export interface SeatMapOrderByInput {
  createdAt?: SortDirection | null;
  imageId?: SortDirection | null;
  name?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueId?: SortDirection | null;
}

export interface StringFilterInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  in?: string[] | null;
  mode?: QueryMode | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TicketCreateInput {
  eventOccurrenceId?: string | null;
  quantity?: number | null;
  ticketPoolId?: number | null;
}

export interface TicketFilterInput {
  AND?: TicketFilterInput[] | null;
  NOT?: TicketFilterInput[] | null;
  OR?: TicketFilterInput[] | null;
  createdAt?: DateTimeFilterInput | null;
  date?: DateTimeFilterInput | null;
  discountAmount?: FloatFilterInput | null;
  expireAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  name?: StringFilterInput | null;
  order?: OrderFilterInput | null;
  orderId?: IntFilterInput | null;
  price?: FloatFilterInput | null;
  status?: TicketStatusEnumFilter | null;
  ticketNumber?: StringFilterInput | null;
  ticketPool?: TicketPoolFilterInput | null;
  ticketPoolId?: IntFilterInput | null;
  totalAmount?: FloatFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venueFeeAmount?: FloatFilterInput | null;
}

export interface TicketListRelationFilterInput {
  every?: TicketFilterInput | null;
  none?: TicketFilterInput | null;
  some?: TicketFilterInput | null;
}

export interface TicketOrderByInput {
  createdAt?: SortDirection | null;
  date?: SortDirection | null;
  discountAmount?: SortDirection | null;
  expireAt?: SortDirection | null;
  id?: SortDirection | null;
  name?: SortDirection | null;
  order?: OrderOrderByInput | null;
  orderId?: SortDirection | null;
  price?: SortDirection | null;
  ticketNumber?: SortDirection | null;
  ticketPoolId?: SortDirection | null;
  totalAmount?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueFeeAmount?: SortDirection | null;
}

export interface TicketPoolCreateInput {
  availableBeforeDays?: number | null;
  availableBeforeTime?: string | null;
  description?: string | null;
  expireBeforeDays?: number | null;
  expireBeforeTime?: string | null;
  isAutoApprove?: boolean | null;
  name?: string | null;
  price?: number | null;
  quantity?: number | null;
  venueFee?: number | null;
}

export interface TicketPoolFilterInput {
  AND?: TicketPoolFilterInput[] | null;
  NOT?: TicketPoolFilterInput[] | null;
  OR?: TicketPoolFilterInput[] | null;
  availableBeforeDays?: IntFilterInput | null;
  availableBeforeTime?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  description?: StringFilterInput | null;
  event?: EventFilterInput | null;
  eventId?: IntFilterInput | null;
  expireBeforeDays?: IntFilterInput | null;
  expireBeforeTime?: StringFilterInput | null;
  id?: IntFilterInput | null;
  isAutoApprove?: BooleanFilterInput | null;
  name?: StringFilterInput | null;
  price?: FloatFilterInput | null;
  quantity?: IntFilterInput | null;
  ticketTemplateId?: IntFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venueFee?: FloatFilterInput | null;
}

export interface TicketPoolListRelationFilterInput {
  every?: TicketPoolFilterInput | null;
  none?: TicketPoolFilterInput | null;
  some?: TicketPoolFilterInput | null;
}

export interface TicketPoolOrderByInput {
  availableBeforeDays?: SortDirection | null;
  availableBeforeTime?: SortDirection | null;
  createdAt?: SortDirection | null;
  eventId?: SortDirection | null;
  expireBeforeDays?: SortDirection | null;
  expireBeforeTime?: SortDirection | null;
  id?: SortDirection | null;
  isAutoApprove?: SortDirection | null;
  name?: SortDirection | null;
  price?: SortDirection | null;
  quantity?: SortDirection | null;
  ticketTemplateId?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueFee?: SortDirection | null;
}

export interface TicketStatusEnumFilter {
  equals?: TicketStatus | null;
  in?: TicketStatus[] | null;
  not?: TicketStatusEnumFilter | null;
  notIn?: TicketStatus[] | null;
}

export interface TicketTemplateCreateInput {
  name?: string | null;
  ticketPools?: TicketPoolCreateInput[] | null;
  venueId?: number | null;
}

export interface TicketTemplateFilterInput {
  AND?: TicketTemplateFilterInput[] | null;
  NOT?: TicketTemplateFilterInput[] | null;
  OR?: TicketTemplateFilterInput[] | null;
  createdAt?: DateTimeFilterInput | null;
  id?: IntFilterInput | null;
  name?: StringFilterInput | null;
  ticketPools?: TicketPoolListRelationFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  venueId?: IntFilterInput | null;
}

export interface TicketTemplateOrderByInput {
  createdAt?: SortDirection | null;
  id?: SortDirection | null;
  name?: SortDirection | null;
  updatedAt?: SortDirection | null;
  venueId?: SortDirection | null;
}

export interface TicketTemplateUpdateInput {
  name?: string | null;
  ticketPools?: TicketPoolCreateInput[] | null;
}

export interface TicketUpdateInput {
  status?: TicketStatus | null;
}

export interface UserCreateInput {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  role: UserRole;
  venueUsers?: VenueUserCreateInput[] | null;
}

export interface UserFilterInput {
  AND?: UserFilterInput[] | null;
  NOT?: UserFilterInput[] | null;
  OR?: UserFilterInput[] | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  phoneNumber?: StringFilterInput | null;
  role?: UserRoleEnumFilter | null;
  venueUsers?: VenueUserListRelationFilterInput | null;
}

export interface UserOrderByInput {
  createdAt?: SortDirection | null;
  firstName?: SortDirection | null;
  lastName?: SortDirection | null;
  phoneNumber?: SortDirection | null;
  role?: SortDirection | null;
  updatedAt?: SortDirection | null;
}

export interface UserRoleEnumFilter {
  equals?: UserRole | null;
  in?: UserRole[] | null;
  not?: UserRoleEnumFilter | null;
  notIn?: UserRole[] | null;
}

export interface UserUpdateInput {
  firstName?: string | null;
  lastName?: string | null;
  role?: UserRole | null;
  venueUsers?: VenueUserCreateInput[] | null;
}

export interface VenueCreateInput {
  address?: AddressUpdateInput | null;
  name?: string | null;
  organizationId?: number | null;
  platformFee?: number | null;
  stripeAccountId?: string | null;
  timezone?: string | null;
}

export interface VenueFilterInput {
  address?: AddressFilterInput | null;
  id?: IntFilterInput | null;
  name?: StringFilterInput | null;
  organization?: OrganizationsFilterInput | null;
  organizationId?: IntFilterInput | null;
  platformFee?: IntFilterInput | null;
  stripeAccountId?: StringFilterInput | null;
}

export interface VenueOrderByInput {
  address?: AddressOrderByInput | null;
  addressId?: SortDirection | null;
  createdAt?: SortDirection | null;
  name?: SortDirection | null;
  organization?: OrganizationOrderByInput | null;
  organizationId?: SortDirection | null;
  platformFee?: SortDirection | null;
  updatedAt?: SortDirection | null;
}

export interface VenueUpdateInput {
  address?: AddressUpdateInput | null;
  name?: string | null;
  platformFee?: number | null;
  stripeAccountId?: string | null;
  timezone?: string | null;
}

export interface VenueUserCreateInput {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  role?: VenueUserRole | null;
  venueId?: number | null;
}

export interface VenueUserFilterInput {
  AND?: VenueUserFilterInput[] | null;
  NOT?: VenueUserFilterInput[] | null;
  OR?: VenueUserFilterInput[] | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  role?: VenueUserRoleEnumFilter | null;
  user?: UserFilterInput | null;
  userId?: IntFilterInput | null;
  venue?: VenueFilterInput | null;
  venueId?: IntFilterInput | null;
}

export interface VenueUserListRelationFilterInput {
  every?: VenueUserFilterInput | null;
  none?: VenueUserFilterInput | null;
  some?: VenueUserFilterInput | null;
}

export interface VenueUserOrderByInput {
  createdAt?: SortDirection | null;
  firstName?: SortDirection | null;
  lastName?: SortDirection | null;
  role?: SortDirection | null;
  updatedAt?: SortDirection | null;
  user?: UserOrderByInput | null;
  venue?: VenueOrderByInput | null;
}

export interface VenueUserRoleEnumFilter {
  equals?: VenueUserRole | null;
  in?: VenueUserRole[] | null;
  not?: VenueUserRoleEnumFilter | null;
  notIn?: VenueUserRole[] | null;
}

export interface VenueUserUpdateInput {
  firstName?: string | null;
  lastName?: string | null;
  role?: VenueUserRole | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
