import { gql } from "@apollo/client";

export const TICKET_POOL_FRAGMENT = gql`
  fragment TicketPool on TicketPool {
    id
    name
    quantity
    price
    venueFee
    availableBeforeDays
    availableBeforeTime
    expireBeforeDays
    expireBeforeTime
    description
    eventId
    ticketTemplateId
    isAutoApprove
    createdAt
    updatedAt
  }
`;
