import { gql } from "@apollo/client";

export const RECURRING_SETTINGS_FRAGMENT = gql`
  fragment RecurringSettings on RecurringSettings {
    id
    createdAt
    endAt
    isOnMonday
    isOnTuesday
    isOnWednesday
    isOnThursday
    isOnFriday
    isOnSaturday
    isOnSunday
    maxOccurrences
    monthRepeatMode
    separator
    separatorCount
    updatedAt
  }
`;
