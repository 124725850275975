import Inner from "components/styles/Inner";
import EventDetails from "components/organisms/EventDetails";
import Image from "components/atoms/Image";
import Wysiwyg from "components/atoms/Wysiwyg";
import Video from "components/atoms/Video";
import Skeleton from "components/atoms/Skeleton";
import SummaryBar from "components/organisms/SummaryBar";

import * as Styled from "./styles";
import { EventDetailsSectionProps } from "./types";

const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
  image,
  video,
  seatMap,
  description,
  bootleServiceCheckbox,
  eventHeading,
  loading,
  summaryBar,
  submitLabel,
  onSubmit,
  ...rest
}) => (
  <Styled.EventDetailsSection>
    <Inner>
      <Styled.EventDetailsSectionBody>
        {(image || loading) && (
          <Styled.EventDetailsSectionMedia>
            {loading ? <Skeleton height={400} /> : <Image {...image} />}
          </Styled.EventDetailsSectionMedia>
        )}
        {(description || loading) && (
          <Styled.EventDetailsSectionWysiwyg>
            {loading ? (
              <Skeleton count={5} />
            ) : (
              <Wysiwyg>{description}</Wysiwyg>
            )}
          </Styled.EventDetailsSectionWysiwyg>
        )}
        {video && (
          <Styled.EventDetailsSectionVideo>
            <Video {...video} />
          </Styled.EventDetailsSectionVideo>
        )}
        <Styled.EventDetailsSectionTickets>
          <EventDetails
            {...{ loading, eventHeading, bootleServiceCheckbox, ...rest }}
          />
        </Styled.EventDetailsSectionTickets>
        {seatMap && (
          <Styled.EventDetailsSectionSeatMap>
            <Image {...seatMap} />
          </Styled.EventDetailsSectionSeatMap>
        )}
      </Styled.EventDetailsSectionBody>
    </Inner>
    <SummaryBar
      {...summaryBar}
      onClick={onSubmit}
      buttonLabel={submitLabel || summaryBar.buttonLabel}
    />
  </Styled.EventDetailsSection>
);

export default EventDetailsSection;
