import Text from "components/styles/Typography";
import TicketsList from "components/molecules/TicketsList";
import Checkbox from "components/atoms/Fields/Checkbox";
import Tooltip from "components/atoms/Tooltip";
import EventHeading from "components/molecules/EventHeading";

import * as Styled from "./styles";
import { EventDetailsProps } from "./types";

const EventDetails: React.FC<EventDetailsProps> = ({
  items,
  onSaleLabel,
  onItemsChange,
  inquireForPricingLabel,
  disabled,
  checkboxTooltip,
  bootleServiceCheckbox,
  eventHeading,
}) => (
  <Styled.EventDetails>
    {eventHeading && <EventHeading {...eventHeading} $isLeftOnDesktop />}
    <Styled.EventDetailsBody>
      <TicketsList {...{ items, disabled, onSaleLabel, onItemsChange }} />
    </Styled.EventDetailsBody>
    {!!items?.length && inquireForPricingLabel && bootleServiceCheckbox && (
      <Styled.EventDetailsFooter>
        <Styled.EventDetailsCheckbox>
          <Checkbox {...bootleServiceCheckbox} />
          {checkboxTooltip && (
            <Styled.EventDetailsTooltip>
              <Tooltip label={checkboxTooltip} />
            </Styled.EventDetailsTooltip>
          )}
        </Styled.EventDetailsCheckbox>
        <Text variant="typo4" ml={["", "1.5rem"]}>
          {inquireForPricingLabel}
        </Text>
      </Styled.EventDetailsFooter>
    )}
  </Styled.EventDetails>
);

export default EventDetails;
