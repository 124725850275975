import { gql } from "@apollo/client";

export const EVENT_FRAGMENT = gql`
  fragment Event on Event {
    id
    ageLimit
    date
    doorTime
    name
    publishAt
    status
    type
    venueId
    youtubeUrl
    description
    timezone
    recurringSettingsId
  }
`;
