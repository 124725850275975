import { useIntl } from "react-intl";
import { useMemo } from "react";
import {
  formatDateTime,
  getSaleDate,
  parseDate,
  zonedTimeToLocal,
} from "utils/date";
import { useOrderContext } from "providers/OrderProvider/hooks";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import { TicketsListItemProps } from "@ticketingplatform/ui/dist/components/molecules/TicketsList/types";
import useDebounceFn from "hooks/useDebounceFn";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import useEventOccurrence from "hooks/useEventOccurrence";
import { EventDetailsSectionProps } from "@ticketingplatform/ui/dist/components/sections/EventDetailsSection/types";
import environment from "setup/environment";
import getYoutubeEmbedUrl from "utils/getYoutubeEmbedUrl";
import getYoutubeVideoId from "utils/getYoutubeVideoId";

export const useEventDetails = (): Pick<
  EventDetailsSectionProps,
  "image" | "description" | "video" | "seatMap" | "onSubmit" | "loading"
> => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { eventOccurrence, loading } = useEventOccurrence();
  const { order } = useOrderContext();

  return useMemo(() => {
    const eventDate = parseDate(eventOccurrence?.date);

    if (loading || !eventOccurrence || !eventDate) {
      return { loading };
    }

    const {
      event: { name, image, description, youtubeUrl, seatMap },
    } = eventOccurrence;
    const youtubeEmbedUrl = getYoutubeEmbedUrl(getYoutubeVideoId(youtubeUrl));

    return {
      image: image?.url
        ? {
            src: image.url,
            alt: intl.formatMessage(
              { defaultMessage: "{name} poster" },
              { name }
            ),
          }
        : undefined,
      description: description && (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      ),
      video: youtubeEmbedUrl
        ? {
            src: youtubeEmbedUrl,
          }
        : undefined,
      seatMap: seatMap?.image?.url
        ? {
            src: seatMap?.image?.url,
            alt: seatMap.name,
          }
        : undefined,
      onSubmit() {
        const expireAt = parseDate(order?.expireAt);

        if (
          !eventOccurrence ||
          !expireAt ||
          isPast(expireAt) ||
          !order?.tickets?.nodes.length
        ) {
          return;
        }

        navigate(
          generatePath(ROUTE_PATHS.EVENT_BILLING, {
            eventOccurrenceId: eventOccurrence.id,
          })
        );
      },
    };
  }, [
    eventOccurrence,
    intl,
    loading,
    navigate,
    order?.expireAt,
    order?.tickets?.nodes.length,
  ]);
};

export const useEventTickets = (): Pick<
  EventDetailsSectionProps,
  "items" | "onItemsChange" | "onSubmit" | "loading" | "disabled"
> => {
  const intl = useIntl();
  const { eventOccurrence, loading } = useEventOccurrence();
  const { loading: disabled, order, error, setOrder } = useOrderContext();

  const onItemsChange = useDebounceFn((items: TicketsListItemProps[]) => {
    setOrder({
      tickets: items.map(({ ticketPoolId, value }) => ({
        ticketPoolId,
        eventOccurrenceId: eventOccurrence?.id,
        quantity: value,
      })),
    });
  }, 1000);

  const items = useMemo(() => {
    const eventDate = parseDate(eventOccurrence?.date);

    if (!eventDate) {
      return [];
    }

    const quantityValue = (order?.tickets?.nodes || []).reduce<
      Record<number, number>
    >(
      (prev, { ticketPoolId }) => ({
        ...prev,
        [ticketPoolId]: (prev[ticketPoolId] ?? 0) + 1,
      }),
      {}
    );

    const { ticketPools, timezone, venue } = eventOccurrence?.event || {};
    const { platformFee } = venue || {};

    return ticketPools?.nodes.map(
      (
        {
          id,
          name,
          description,
          price,
          availableBeforeDays,
          availableBeforeTime,
          expireBeforeDays,
          expireBeforeTime,
          quantity: maxValue,
        },
        index
      ) => {
        const saleAvailableAt =
          availableBeforeDays !== null &&
          availableBeforeTime !== null &&
          getSaleDate(eventDate, availableBeforeDays, availableBeforeTime);

        const saleExpireAt =
          expireBeforeDays !== null &&
          expireBeforeTime &&
          getSaleDate(eventDate, expireBeforeDays, expireBeforeTime);

        const localSaleAvailableAt =
          saleAvailableAt && zonedTimeToLocal(saleAvailableAt, timezone);

        const localSaleExpireAt =
          saleExpireAt && zonedTimeToLocal(saleExpireAt, timezone);

        const isSaleAvailable =
          (!localSaleAvailableAt || isPast(localSaleAvailableAt)) &&
          (!localSaleExpireAt || isFuture(localSaleExpireAt));

        return {
          name,
          ticketPoolId: id,
          key: id,
          error: error?.graphQLErrors.find((graphQLError) =>
            graphQLError.errorInfo?.path?.startsWith(`input.tickets.[${index}]`)
          )?.message,
          price: price
            ? intl.formatNumber(price, {
                style: "currency",
                currency: environment.CURRENCY,
              })
            : intl.formatMessage({
                defaultMessage: "Free",
              }),
          feeLabel:
            price && platformFee
              ? intl.formatMessage({
                  defaultMessage: "Fees",
                  description: "Fees label",
                })
              : undefined,
          dateTime: saleAvailableAt
            ? formatDateTime(saleAvailableAt, timezone)
            : undefined,
          tooltipLabel: description && (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ),
          disabled: !isSaleAvailable,
          value: quantityValue[id] ?? 0,
          maxValue,
        };
      }
    );
  }, [
    error?.graphQLErrors,
    eventOccurrence?.date,
    eventOccurrence?.event,
    intl,
    order?.tickets?.nodes,
  ]);

  return {
    items,
    onItemsChange,
    loading,
    disabled,
  };
};
