import { EventHeadingProps } from "@ticketingplatform/ui/dist/components/molecules/EventHeading/types";
import { useMemo } from "react";
import { parseDate, formatDateTime } from "utils/date";

import useEventOccurrence from "./useEventOccurrence";

const useEventHeading = (): EventHeadingProps => {
  const { eventOccurrence, loading } = useEventOccurrence();

  return useMemo(() => {
    const eventDate = parseDate(eventOccurrence?.date);

    if (loading || !eventOccurrence || !eventDate) {
      return { loading };
    }

    const {
      event: { name, timezone, venue },
    } = eventOccurrence;

    return {
      name,
      dateTime: formatDateTime(eventDate, timezone),
      venue: [
        venue?.name,
        venue?.address?.city && ` - ${venue?.address?.city}`,
        venue?.address?.provinceCode && `, ${venue?.address?.provinceCode}`,
      ]
        .filter(Boolean)
        .join(""),
    };
  }, [eventOccurrence, loading]);
};

export default useEventHeading;
