import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useOrderContext } from "providers/OrderProvider/hooks";
import environment from "setup/environment";

import { UseSummaryBar } from "./types";

const useSummaryBar: UseSummaryBar = ({ withFees } = {}) => {
  const intl = useIntl();
  const { order } = useOrderContext();

  return useMemo(
    () => ({
      quantityLabel: intl.formatMessage({
        defaultMessage: "QTY",
        description: "Tickets quantity label",
      }),
      buttonLabel: intl.formatMessage({
        defaultMessage: "Checkout",
        description: "Summary bar button label label",
      }),
      totalLabel: intl.formatMessage({
        defaultMessage: "Total",
        description: "Total label",
      }),
      disabled: !order?.tickets?.nodes.length,
      quantity: order?.tickets?.nodes.length || 0,
      total: withFees
        ? intl.formatNumber(order?.totalAmount || 0, {
            style: "currency",
            currency: environment.CURRENCY,
          })
        : intl.formatMessage(
            { defaultMessage: "{value} + Fees" },
            {
              value: intl.formatNumber(order?.subtotalAmount || 0, {
                style: "currency",
                currency: environment.CURRENCY,
              }),
            }
          ),
    }),
    [
      intl,
      order?.subtotalAmount,
      order?.tickets?.nodes.length,
      order?.totalAmount,
      withFees,
    ]
  );
};

export default useSummaryBar;
