import styled from "styled-components";

export const TicketsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TicketsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
`;

export const TicketsListDetails = styled.div`
  overflow-wrap: anywhere;
`;

export const TicketsListQuantityPicker = styled.div`
  flex-shrink: 0;
  text-align: right;
`;

export const TicketsListTooltip = styled.span`
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.tiara};
`;
