import { gql } from "@apollo/client";

import { EVENT_WITH_RELATIONS_FRAGMENT } from "./fragments/eventWithRelations";
import { EVENT_OCCURRENCE_FRAGMENT } from "./fragments/eventOccurrence";
import { EVENT_OCCURRENCE_WITH_RELATIONS_FRAGMENT } from "./fragments/eventOccurrenceWithRelations";

export const GET_EVENTS = gql`
  ${EVENT_WITH_RELATIONS_FRAGMENT}
  query GetEvents(
    $take: Int
    $skip: Int
    $filters: EventFilterInput
    $orderBy: [EventOrderByInput!]
    $recurringSettingsInclude: Boolean = false
    $venueInclude: Boolean = false
    $imageInclude: Boolean = false
    $seatMapInclude: Boolean = false
    $ticketPoolsInclude: Boolean = false
    $ticketPoolsSkip: Int
    $ticketPoolsTake: Int
    $ticketPoolsFilters: TicketPoolFilterInput
    $ticketPoolsOrderBy: [TicketPoolOrderByInput!]
    $discountCodesInclude: Boolean = false
    $discountCodesSkip: Int
    $discountCodesTake: Int
    $discountCodesFilters: DiscountCodeFilterInput
    $discountCodesOrderBy: [DiscountCodeOrderByInput!]
  ) {
    events(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy) {
      count
      nodes {
        ...EventWithRelations
      }
    }
  }
`;

export const GET_EVENT = gql`
  ${EVENT_WITH_RELATIONS_FRAGMENT}
  query GetEvent(
    $id: Int!
    $recurringSettingsInclude: Boolean = false
    $venueInclude: Boolean = false
    $imageInclude: Boolean = false
    $seatMapInclude: Boolean = false
    $ticketPoolsInclude: Boolean = false
    $ticketPoolsSkip: Int
    $ticketPoolsTake: Int
    $ticketPoolsFilters: TicketPoolFilterInput
    $ticketPoolsOrderBy: [TicketPoolOrderByInput!]
    $discountCodesInclude: Boolean = false
    $discountCodesSkip: Int
    $discountCodesTake: Int
    $discountCodesFilters: DiscountCodeFilterInput
    $discountCodesOrderBy: [DiscountCodeOrderByInput!]
  ) {
    event(id: $id) {
      ...EventWithRelations
    }
  }
`;

export const GET_EVENT_OCCURRENCES = gql`
  ${EVENT_OCCURRENCE_WITH_RELATIONS_FRAGMENT}
  query GetEventOccurrences(
    $start: AWSDateTime!
    $end: AWSDateTime!
    $filters: EventOccurrenceFilterInput
    $eventInclude: Boolean = false
    $ticketsAggregationInclude: Boolean = false
    $recurringSettingsInclude: Boolean = false
    $venueInclude: Boolean = false
    $imageInclude: Boolean = false
    $seatMapInclude: Boolean = false
    $ticketPoolsInclude: Boolean = false
    $ticketPoolsSkip: Int
    $ticketPoolsTake: Int
    $ticketPoolsFilters: TicketPoolFilterInput
    $ticketPoolsOrderBy: [TicketPoolOrderByInput!]
    $discountCodesInclude: Boolean = false
    $discountCodesSkip: Int
    $discountCodesTake: Int
    $discountCodesFilters: DiscountCodeFilterInput
    $discountCodesOrderBy: [DiscountCodeOrderByInput!]
  ) {
    eventOccurrences(start: $start, end: $end, filters: $filters) {
      nodes {
        ...EventOccurrenceWithRelations
      }
    }
  }
`;

export const GET_EVENT_OCCURRENCE = gql`
  ${EVENT_OCCURRENCE_WITH_RELATIONS_FRAGMENT}
  query GetEventOccurrence(
    $id: String!
    $eventInclude: Boolean = false
    $ticketsAggregationInclude: Boolean = false
    $recurringSettingsInclude: Boolean = false
    $venueInclude: Boolean = false
    $imageInclude: Boolean = false
    $seatMapInclude: Boolean = false
    $ticketPoolsInclude: Boolean = false
    $ticketPoolsSkip: Int
    $ticketPoolsTake: Int
    $ticketPoolsFilters: TicketPoolFilterInput
    $ticketPoolsOrderBy: [TicketPoolOrderByInput!]
    $discountCodesInclude: Boolean = false
    $discountCodesSkip: Int
    $discountCodesTake: Int
    $discountCodesFilters: DiscountCodeFilterInput
    $discountCodesOrderBy: [DiscountCodeOrderByInput!]
  ) {
    eventOccurrence(id: $id) {
      ...EventOccurrenceWithRelations
    }
  }
`;
