import { useIntl } from "react-intl";
import EventDetailsSection from "@ticketingplatform/ui/dist/components/sections/EventDetailsSection";
import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useNavItems from "hooks/useNavItems";
import ScrollToTop from "components/ScrollToTop";
import SessionInfoBar from "components/SessionInfoBar";
import useEventHeading from "hooks/useEventHeading";
import useSessionInfoBar from "hooks/useSessionInfoBar";
import useSummaryBar from "hooks/useSummaryBar";

import { useEventDetails, useEventTickets } from "./hooks";

const EventDetailsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navItems = useNavItems();
  const eventDetails = useEventDetails();
  const eventTickets = useEventTickets();
  const eventHeading = useEventHeading();
  const summaryBar = useSummaryBar();
  const sessionInfoBar = useSessionInfoBar();

  return (
    <>
      <ScrollToTop />
      <Header href="/" items={navItems} withCloseIcon closeHref="/" />
      <EventDetailsSection
        onSaleLabel={formatMessage({
          defaultMessage: "On Sale",
          description: "On Sale label",
        })}
        submitLabel={formatMessage({
          defaultMessage: "Next",
          description: "Summary bar button label label",
        })}
        {...eventDetails}
        {...eventTickets}
        {...{ eventHeading, summaryBar }}
      />
      {sessionInfoBar && <SessionInfoBar {...sessionInfoBar} />}
    </>
  );
};

export default EventDetailsPage;
