import styled, { css } from "styled-components";
import media from "theme/config/media";

const commonWysiwyg = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1rem;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  a {
    transition: ${({ theme }) => theme.transition};
  }

  @media ${media.tablet} {
    a:hover {
      color: ${({ theme }) => theme.colors.tiara};
    }
  }
`;

export const WysiwygParagraph = styled.p`
  ${commonWysiwyg}
`;

export const WysiwygContainer = styled.div`
  ${commonWysiwyg}
`;
